body.home {
	section:not(:last-child) {
		margin-bottom: 60px;
		@include breakpoint(medium-up) {
			margin-bottom: 100px;
		}
	}
	
	.section-title {
		margin-bottom: 20px;
		@include breakpoint(medium-up) {
			margin-bottom: 60px;
		}
	}
	
	a.button {
		position: relative;
		border-width: 2px;
		font-weight: bold;
		&:after {
			font-family: FontAwesome;
			content: "\f055";
			color: $alert-color;
			position: absolute;
			right: 10px;
		}
	}
	
	#welcome {
		display: flex;
		text-align: center;
		justify-content: center;
		background: url(../images/hero.jpg) no-repeat;
		background-position: center center;
		background-size: cover;
		height: 360px;
		.inner {
			width: 70%;
			max-width: 520px;
			height: 100%;
			background: rgba($secondary-color, .9);
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 30px;
		}
		h2 {
			margin: 0;
		}
		@include breakpoint(medium-up) {
			height: 500px;
		}
		@include breakpoint(large-up) {
			height: calc(100vh - 152px);
		}
	}
	
	#message {
		@extend .container;
		margin-bottom: 40px;
		font-family: $body-font-family-serif;
		@include breakpoint(medium-up) {
			margin-bottom: 70px;
			p {
				text-align: center;
				font-size: 18px;
				letter-spacing: 1px;
				line-height: 2;
				span {
					display: block;
				}
			}
		}
	}
	
	#section-movie {
		ul.movie-list {
			display: flex;
			flex-direction: column;
			list-style: none;
			margin: 0 0 20px;
			padding: 0;
			li {
				margin-bottom: 10px;
				> div {
					padding: 0 15px;
				}
			}
			h4 {
				font-size: $global-font-size;
				margin-bottom: 0;
			}
			figure {
				margin: 1rem 10px 4px;
			}
			iframe {
				width: 100%;
				min-height: 200px;
			}
			@include breakpoint(medium-up) {
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 60px;
//				&:after {
//					content: "";
//					display: block;
//					width: 31%;
//				}
				li {
					width: 31%;
					> div {
						padding: 0;
					}
				}
				figure {
					margin: 0;
				}
			}
		}
	}
	
	.contents {
		background: rgba($primary-color, .05);
		padding: 30px 0;
		@include breakpoint(medium-up) {
			background: url(../images/front-page/contents_background.jpg) no-repeat;
			background-position: top center;
			background-size: cover;
		}
		section {
			h3 {
				text-align: center;
				color: $primary-color;
				font-size: 22px;
				&:after {
					display: block;
					content: "";
					width: 30px;
					height: 1px;
					margin: 10px auto;
					background-color: $primary-color;
				}
			}
			@include breakpoint(medium-up) {
				max-width: $global-width;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				margin-bottom: 160px;
				h3 {
					font-size: 30px;
					margin-bottom: 20px;
				}
				figure {
					width: 40%;
					margin: 0 20px;
				}
				.textbox {
					width: 50%;
					padding: 0 10px;
				}
				.button-area {
					margin-top: 30px;
				}
				&:nth-child(even) {
					flex-direction: row-reverse;
				}
				&#section-demands {
					display: block;
					.textbox {
						width: 800px;
						margin: 0 auto
					}
					h3 {
						margin-bottom: 60px;
					}
				}
			}
		}
		.textbox {
			background: $white;
			margin: 0 15px;
			padding: 20px 15px;
			@include breakpoint(medium-up) {
				background: none;
			}
		}
		ul.demands {
			list-style: none;
			padding: 0;
			li {
				background: rgba($primary-color, .9);
				color: $white;
				padding: 15px;
				margin-bottom: 12px;
				position: relative;
				text-align: center;
				font-weight: bold;
				letter-spacing: 1px;
				&:before {
					content: "1";
					background: $alert-color;
					border: 2px solid $white;
					color: $white;
					left: -10px;
					top: -8px;
					border-radius: 50%;
					width: 30px;
					height: 30px;
					display: flex;
					position: absolute;
					align-items: center;
					justify-content: center;
					font-weight: normal;
					letter-spacing: 0;
				}
				&:nth-child(2):before { content: "2"; }
				&:nth-child(3):before { content: "3"; }
				&:nth-child(4):before { content: "4"; }
			}
			@include breakpoint(medium-up) {
				li {
					padding: 40px 30px;
					font-size: 20px;
					margin-bottom: 40px;
					&:before {
						font-size: 26px;
						width: 50px;
						height: 50px;
						left: 50%;
						top: -25px;
						margin-left: -25px;
					}
				}
			}
		}
	}
}