main#contact {
	.container.form {
		> p {
			margin-bottom: 20px;
		}
		@include breakpoint(medium-up) {
			max-width: 900px;
			> p {
				text-align: center;
				line-height: 2.3;
				margin-bottom: 50px;
				span {
					display: block;
				}
			}
		}
	}
	.wpcf7 {
		.screen-reader-response {
			display: none;
		}
		
		form {
			width: 100%;
			p {
				label {
					display: block;
					font-size: 13px;
					margin-bottom: 3px;
				}
				span.required {
					color: $alert-color;
				}
				span.wpcf7-not-valid-tip {
					color: $alert-color;
				}
			}
			input, textarea {
				border: 1px solid $medium-gray;
				border-radius: 3px;
				padding: 10px;
				width: 100%;
				box-sizing: border-box;
				@include breakpoint(medium-up) {
					padding: 15px;
				}
			}
			input[type="submit"] {
				background: $secondary-color;
				color: $white;
				padding: 15px;
				transition: all .5s;
				&:hover {
					background: darken($secondary-color, 7%);
				}
				@include breakpoint(medium-up) {
					width: auto;
					padding: 20px 60px;
					letter-spacing: 2px;
				}
			}
			.btn-wrap {
				text-align: center;
				margin-bottom: 20px;
			}
			.wpcf7-response-output {
				@include breakpoint(medium-up) {
					text-align: center;
				}
			}
		}
	}
}