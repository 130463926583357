#global-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  .top {
	  background: rgba($white, .95);
	  width: 100%;
	  height: 56px;
	  position: fixed;
	  z-index: 100;
  }
  h1 {
    font-size: 24px;
    position: absolute;
    line-height: 0;
    top: 13px;
    left: 10px;
    z-index: 100;
    width: 180px;
    margin-bottom: 0;
    a:hover {
	    opacity: .8;
    }
  }
  ul {
	  list-style: none;
	  padding: 0;
	  margin: 0;
  }

  nav.main-nav {
    position: absolute;
    top: -500px;
    background: rgba($white,0.96);
    width: 100%;
    text-align: center;
    padding-top: 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    z-index: 80;
    ul {
	    display: flex;
	    flex-direction: column;
      text-align: center;
      li {
        border-bottom: 1px solid $medium-gray;
        font-weight: bold;
        letter-spacing: 2px;
        a {
          width: 100%;
          display: block;
          padding: 12px 0;
          position: relative;
          &::after {
	          font-family: FontAwesome;
	          content: "\f105";
	          position: absolute;
	          right: 10px;
          }
        }
        &.inquiry {
	        a {
		        background: $alert-color;
		        color: $white;
	        }
        }
      }
    }
  }
  nav.sub-nav {
	  display: none;
	  @include breakpoint(large-up) {
		  display: block;
	  }
  }
  #nav-toggle {
    position: absolute;
    right: 14px;
    top: 18px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    z-index: 100;
    div {
      position: relative;
    }
    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: $black;
      left: 0;
      -webkit-transition: .35s ease-in-out;
      -moz-transition: .35s ease-in-out;
      transition: .35s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 10px;
      }
      &:nth-child(3) {
        top: 20px;
      }
    }
  }
  &.open {
    nav.main-nav {
      /* #global-nav top + #mobile-head height */
      -moz-transform: translateY(556px);
      -webkit-transform: translateY(556px);
      transform: translateY(556px);
    }
    #nav-toggle span:nth-child(1) {
      top: 11px;
      -webkit-transform: rotate(315deg);
      -moz-transform: rotate(315deg);
      transform: rotate(315deg);
    }
    #nav-toggle span:nth-child(2) {
      width: 0;
      left: 50%;
    }
    #nav-toggle span:nth-child(3) {
      top: 11px;
      -webkit-transform: rotate(-315deg);
      -moz-transform: rotate(-315deg);
      transform: rotate(-315deg);
    }
  }
  
  @include breakpoint(large-up) {
	  position: static;
	  h1 {
		  width: 337px;
		  left: 20px;
	  }
	  .top {
		  position: static;
		  height: 82px;
		  border-bottom: 1px solid $light-gray;
	  }
	  .bottom {
		  position: absolute;
		  width: 100%;
		  height: 70px;
		  z-index: 9999;
		  border-bottom: 1px solid $medium-gray;
	  }
	  &.fixed {
		  .bottom {
			  position: fixed;
			  top: 0;
			  left: 0;
		  }
	  }
	  nav.main-nav {
		  display: block;
		  top: 0;
		  ul {
			  flex-direction: row;
			  justify-content: space-around;
			  max-width: 1200px;
			  margin: 0 auto;
			  li {
				  border: none;
				  width: calc(100% / 7);
				  a {
					  display: block;
					  letter-spacing: 1px;
					  padding: 21px 0;
						&::before,
						&::after {
						  border-bottom: solid 4px $secondary-color;
						  bottom: 0;
						  content: "";
						  display: block;
						  position: absolute;
						  transition: all .3s ease;
						  -webkit-transition: all .3s ease;
						  width: 0;
						}
						&::before {
						  left: 50%;
						}
						&::after {
						  right: 50%;
						}
						&:hover::before,
						&:hover::after {
						  width: 50%;
						}					  
					  span {
						  display: block;
						  border-right: 1px solid $medium-gray;
					  }
				  }
				  &:first-child {
					  a span {
						  border-left: 1px solid $medium-gray;
					  }
				  }
			  }
		  }
	  }
	  nav.sub-nav {
		  position: absolute;
		  top: 10px;
		  right: 20px;
		  ul {
			  display: flex;
			  align-items: center;
			  li {
				  &:not(:last-child) {
					  margin-right: 20px;
					  a {
						  &:before {
							  font-family: FontAwesome;
							  content: "\f0ca";
							  margin-right: 6px;
							  font-size: 13px;
							  vertical-align: 1px;
						  }
					  }
				  }
			  }
		  }
		  .button {
			  font-weight: bold;
			  border-radius: 60px;
			  position: relative;
			  &::after {
				  font-family: FontAwesome;
				  content: "\f138";
				  position: absolute;
				  right: 10px;
			  }
		  }
	  }
	  #nav-toggle {
		  display: none;
	  }
  }
}


// facebook & instagram
// --------------------

#fixed-menu {
	display: none;
	@include breakpoint(large up) {
		display: block;
		position: fixed;
		z-index: 10;
		right: 0;
		top: 100px;
		ul {
			list-style: none;
			margin: 0;
			li {
				margin-bottom: 10px;
			}
		}
		a {
			display: flex;
			border-radius: 10px 0 0 10px;
			color: $white;
			width: 44px;
			height: 44px;
			font-size: 24px;
			justify-content: center;
			align-items: center;
			&:hover {
				i {
					opacity: .7;
				}
			}
		}
		li.facebook {
			a {
				background: #3b5998;
			}
		}
		li.instagram {
			a {
				background: linear-gradient(45deg, #f50000, #b900b4, #1400c8);
			}
		}
	}
}

