.clearfix::after{
  content: "";
  display: block;
  clear: both;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-bold {
	font-weight: bold;
}

.mt0 {
	margin-top: 0;
}

.hide-for-medium {
	@include breakpoint(medium-up) {
		display: none;
	}
}

.hide-for-large {
	@include breakpoint(large-up) {
		display: none;
	}
}