// 固定ページ用 最新ニュース一覧
// ----------

ul.news-list {
	list-style: none;
	padding: 0;
	margin-bottom: 30px;
	li {
		border-bottom: 1px dotted $primary-color;
		a {
			display: block;
			padding: 6px 2em 6px 0;
			position: relative;
			color: $black;
			&:after {
				font-family: FontAwesome;
				content: "\f138";
				position: absolute;
				right: 10px;
				top: 50%;
				margin-top: -0.5em;
				line-height: 1;
				color: $primary-color;
			}
			date {
				font-size: 13px;
				color: $primary-color;
				text-decoration: none;
			}
			span.label {
				font-size: 12px;
				background: $dark-gray;
				color: $white;
				padding: 2px 10px;
				margin-left: 6px;
				border-radius: 60px;
				&.lawsuit {
					background: $secondary-color;
				}
				&.activity {
					background: #e28807;
				}
				&.notice {
					background: $alert-color;
				}
			}
			span.text {
				display: block;
			}
			&:hover {
				background: rgba($alert-color, .05);
				text-decoration: none;
			}
		}
		&:first-child {
			border-top: 1px dotted $primary-color;
		}
	}
	
	@include breakpoint(medium-up) {
		margin-bottom: 60px;
		li {
			a {
				padding: 25px 130px;
				date {
					margin-right: 20px;
				}
				span.label {
					display: inline-block;
					text-align: center;
					min-width: 62px;
					line-height: 1;
					padding: 5px 12px 5px 13px;
					margin-right: 10px;
					letter-spacing: 1px;
				}
				span.text {
					display: inline;
				}
			}
		}
	}
}



// ページネーション
// ----------


ul.pagination {
	list-style: none;
	text-align: center;
	padding: 0;
	li {
		display: inline-block;
		margin: 0 2px;
		font-size: 13px;
		a, span {
			display: block;
			width: 26px;
			height: 26px;
			line-height: 26px;
		}
		a {
			background: $white;
			border: 1px solid $secondary-color;
			&:hover {
				background: rgba($secondary-color, .3);
				text-decoration: none;
			}
		}
		span {
			&.current {
				background: $secondary-color;
				border: 1px solid $secondary-color;
				color: $white;
			}
		}
	}
}


// ニュース一覧
// ----------

body.blog, body.category, body.archive {
	
	ul.tabs {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		justify-content: space-between;
		text-align: center;
		li {
			border: 1px solid $primary-color;
			width: 48%;
			margin-bottom: 10px;
			font-size: 13px;
			font-weight: bold;
			border-radius: 60px;
			a {
				color: $primary-color;
				display: block;
				letter-spacing: 1px;
				padding: 3px;
				border-radius: 60px;
				&:hover {
					background: $primary-color;
					color: $white;
				}
			}
			&.active {
				a {
					background: $primary-color;
					color: $white;
				}
			}
		}
		@include breakpoint(medium-up) {
			justify-content: center;
			position: relative;
			&:after {
				display: block;
				content: "";
				width: 100%;
				height: 1px;
				background: $medium-gray;
				position: absolute;
				top: calc(100% - 1px);
				z-index: -1;
			}
			li {
				border: 1px solid $medium-gray;
				background: $white;
				width: 13%;
				min-width: 120px;
				padding: 10px;
				margin-bottom: 0;
				font-size: $global-font-size;
				font-weight: normal;
				border-radius: 0;
				a {
					padding: 12px;
					border-radius: 0;
				}
				&.active {
					border-bottom: none;
				}
				&:not(:first-child) {
					border-left: none;
				}
			}
		}
	}
	
	.article_list {
		list-style: none;
		padding: 0;
		li {
			a {
				display: flex;
				flex-direction: column;
				border-bottom: 1px solid #d2d2d2;
				padding: 15px;
				&:hover {
					background: rgba($alert-color, .05);
				}
			}
			h3 {
				font-size: $global-font-size;
				color: $black;
				margin-bottom: 10px;
			}
			p {
				color: $black;
				font-size: 14px;
				margin-bottom: 3px;
			}
			span.readmore {
				display: block;
				text-align: right;
				font-size: 14px;
				margin-bottom: 10px;
			}
			.label {
				display: inline-block;
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 2px;
				background: $dark-gray;
				color: $white;
				padding: 2px 10px;
				border-radius: 60px;
				margin-bottom: 6px;
				min-width: 100px;
				text-align: center;
				&.lawsuit {
					background: $secondary-color;
				}
				&.activity {
					background: #e28807;
				}
				&.notice {
					background: $alert-color;
				}
			}
			time {
				display: block;
				text-align: right;
				color: $dark-gray;
				font-size: 14px;
			}
		}
		@include breakpoint(medium-up) {
			li {
				a {
					flex-direction: row;
					padding: 20px;
					justify-content: space-between;
				}
				figure {
					width: 35%;
					margin: 0;
				}
				section {
					width: 60%;
				}
				.label {
					margin-bottom: 10px;
				}
				time {
					text-align: left;
				}
			}
		}
	}
}


