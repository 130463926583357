#global-footer {
  margin-top: auto !important; // footer fixed
  
  text-align: center;
  .top {
	  background: $primary-color;
	  .inner {
		  padding: 15px 13px;
		  @include breakpoint(medium-up) {
			  max-width: $global-width;
			  margin: 0 auto;
			  padding: 50px;
			  > p {
				  font-size: 22px;
			  }
		  }
	  }
	  p {
		  color: $white;
		  font-weight: bold;
	  }
	  .box {
		  border-color: $white;
		  text-align: left;
		  border-radius: 3px;
		  p {
			  font-weight: normal;
		  }
	  }
	  a.button {
		  background: $white;
		  color: $primary-color;
		  position: relative;
		  display: block;
		  border-radius: 60px;
		  font-weight: bold;
		  &::after {
			  font-family: FontAwesome;
			  content: "\f138";
			  position: absolute;
			  right: 10px;
		  }
		  &:hover {
			  background: lighten($alert-color, 20%);
		  }
		  @include breakpoint(medium-up) {
			  display: inline-block;
			  width: 400px;
		  }
	  }
	  
  }
  .bottom {
	  padding: 10px;
    background: rgba($alert-color, .1);
    p {	
	    font-size: 11px;
	    font-weight: bold;
	    margin-bottom: 0;
    }
    @include breakpoint(medium-up) {
	    padding: 15px;
    }
  }
}

#back-to-top {
	display: none;
	position: fixed;
	right: 10px;
	bottom: 15px;
	z-index: 90;
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		background: $white;
		color: $primary-color;
		border: 1px solid $primary-color;
		width: 45px;
		height: 45px;
		border-radius: 50%;
		i {
			font-size: 20px;
		}
	}
	@include breakpoint(medium-up) {
		right: 20px;
		bottom: 25px;
	}
}