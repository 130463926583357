.button {
    display: inline-block;
    background: $primary-color;
    color: $white;
    border: 0;
    cursor: pointer;
    padding: $global-unit/1.2 $global-unit*3;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    letter-spacing: 1px;
    line-height: 1;
    transition: all .5s;
    text-decoration: none;

    &:hover {
      color: $white;
      background: darken($primary-color, 7%);
      text-decoration: none;
    }

    &.empty {
      background: transparent;
      color: $white;

      &:hover {
        background: darken($primary-color, 5%);
        color: darken($white, 10%);
      }
    }
    &.border {
	    background: $white;
	    color: $primary-color;
	    border: 1px solid $primary-color;
	    &:hover {
		    background: $primary-color;
		    color: $white;
	    }
    }
    &.radius {
	    border-radius: 3px;
    }

    &.small {
      padding: $global-unit/4 $global-unit/2;
    }

    &.wide {
      display: block;
      width: 100%;
    }

    &.icon {
      .fa {
        margin: 0 $global-unit;
        color: darken($white, 20%);
      }

      &.small .fa
      {
          margin: 0 $global-unit/2;
      }

      .fa:first-child
      {
          margin-left: 0;
      }

      .fa:last-child
      {
          margin-right: 0;
      }
    }
}

@each $cl, $color in $palette {
  .button.#{$cl} {
    background: $color;
    color: contrast-color($color);

    .fa {
      @if $cl == 'black' {
        color: $white;
      } @else if $cl == 'white' {
        color: $black;
      } @else {
        color: darken($color, 20%);
      }
    }

    &:hover {
      @if $cl == 'black' {
        background: lighten($black, 10%);
      } @else {
        background: darken($color, 7%);
      }
    }
    
    &.border {
	    background: $white;
	    color: $color;
	    border-color: $color;
	    &:hover {
		    background: $color;
		    color: $white;
	    }
    }
  }
}
