body.single {
	.label {
		display: inline-block;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 2px;
		background: $dark-gray;
		color: $white;
		padding: 2px 10px;
		border-radius: 60px;
		margin-bottom: 6px;
		min-width: 100px;
		text-align: center;
		&.lawsuit {
			background: $secondary-color;
		}
		&.activity {
			background: #e28807;
		}
		&.notice {
			background: $alert-color;
		}
	}
	
	.flexbox {
		@include breakpoint(medium-up) {
			flex-direction: row-reverse;
			align-items: flex-start;
		}
	}
	
	.page-title {
		@include breakpoint(medium-up) {
			margin-bottom: 20px;
		}
	}
	
	.breadcrumb {
		@include breakpoint(medium-up) {
			margin-bottom: 50px;
		}
	}
	
	article {
		margin-bottom: 30px;
		h3 {
			font-size: 20px;
		}
		h4 {
			font-size: 17px;
		}
		.information {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px dotted $primary-color;
			padding-bottom: 10px;
			margin-bottom: 20px;
			time {
				font-size: 13px;
			}
		}
		figure {
			margin: 1.5rem 15px 1.5rem;
		}
		figcaption {
			font-size: 13px;
			color: $dark-gray;
		}
		@include breakpoint(medium-up) {
			width: 68%;
			margin-bottom: 0;
			h3 {
				font-size: 26px;
			}
		}
	}
	
	.contents {
		margin-bottom: 30px;
		h3 {
			color: $primary-color;
			border-bottom: 1px dotted;
			padding-bottom: 5px;
			margin-bottom: 10px;
		}
		h4 {
			font-size: 16px;
			background: rgba($primary-color, .1);
			border-left: 5px solid $primary-color;
			padding: 6px;
			
		}
		@include breakpoint(medium-up) {
			margin-bottom: 60px;
			h3 {
				margin-bottom: 15px;
			}
			h4 {
				padding: 8px 10px;
			}
		}
	}

	aside {
		section {
			h3 {
				font-weight: bold;
				color: $primary-color;
				background: rgba($primary-color, .1);
				font-size: 18px;
				margin: 0 -15px 10px;
				padding: 5px 15px;
			}
			ul {
				list-style: none;
				padding: 0;
				li {
					border-bottom: 1px dotted $medium-gray;
					font-size: 14px;
					a {
						display: block;
						padding: 5px;
						word-break: break-all;
					}
				}
			}
		}
		@include breakpoint(medium-up) {
			width: 28%;
			section {
				h3 {
					border-bottom: 1px dotted;
					margin: 0 0 15px;
					background: none;
					padding-left: 0;
				}
			}
		}
	}

	.button_area {
		list-style: none;
		text-align: center;
		padding: 0;
		li {
			display: inline-block;
			text-align: center;
			font-weight: bold;
			font-size: 14px;
			margin: 0 10px;
			a {
				border: 1px solid $primary-color;
				color: $primary-color;
				border-radius: 60px;
				padding: 8px 30px;
				display: block;
				&:hover {
					background: $primary-color;
					color: $white;
				}
			}
		}
	}


}