.cartjs_box {
	background: rgba($primary-color, .1);
	border: none !important;
	padding: 15px !important;
	.cartjs_block {
		padding: 0 !important;
	}
	table {
		width: 100% !important;
		th, td {
			background: none !important;
		}
		input[type="submit"] {
			@extend .button;
		}
	}
	p.cartjs_product_price {
		color: $black !important;
		margin: 0 !important;
	}
	input.cartjs_btn {
		background: $warning-color !important;
		text-shadow: none !important;
		box-shadow: none !important;
		font-size: 16px !important;
		border: none !important;
		@include breakpoint(medium-up) {
			width: auto !important;
			padding: 6px 10px !important;
		}
	}
	.cartjs_cart_in {
		input[type="submit"] {
			background: $warning-color !important;
			margin-bottom: 0 !important;
		}
	}
}