body.page-template {
	
	main > .container > section {
		margin-bottom: 60px;
		@include breakpoint(medium-up) {
			margin-bottom: 150px;
		}
	}
	
	figure {
		margin: 0 0 20px;
		@include breakpoint(medium-up) {
			margin: 0 30px 30px;
		}
	}
	
	p.lead {
		@include breakpoint(medium-up) {
			font-size: 17px;
			line-height: 2;
			margin-bottom: 2em;
			text-align: center;
			span {
				display: block;
			}
		}
	}
	
}


// アスベスト被害
// ----------

body.page-template-health-effects {
	.box {
		&.yellow {
			border: none !important;
			background: rgba($alert-color, .1);
			h3 {
				font-size: 22px;
				color: $primary-color;
				@include breakpoint(medium-up) {
					font-size: 28px;
				}
			}
			figure {
				text-align: center;
				img {
					width: 70%;
				}
			}
		}
		
		&.green {
			border: none !important;
			background: rgba($primary-color, .1);
			h4 {
				text-align: center;
				font-size: 17px;
			}
		}
		@include breakpoint(medium-up) {
			h3 {
				text-align: center;
				margin-bottom: 30px;
			}
		}
	}
	
	.section04 {
		dl {
			dt {
				color: $secondary-color;
				font-size: 17px;
				border-bottom: 1px dotted;
				padding-bottom: 3px;
				margin-bottom: 5px;
			}
			dd {
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}
	}
	
	.section05 {
		h4 {
			font-size: 17px;
			color: $primary-color;
			border-bottom: 1px dotted;
			padding-bottom: 5px;
			margin-bottom: 10px;
			margin-top: 30px;
			&.mt0 {
				margin-top: 0;
			}
			@include breakpoint(medium-up) {
				font-size: 20px;
				text-align: center;
				padding-bottom: 15px;
				margin-bottom: 30px;
				margin-top: 100px;
			}
		}
		
		ul.flexbox {
			li {
				@include breakpoint(medium-up) {
					width: 28%;
				}
			}
			figure {
				margin-bottom: 6px;
				img {
					width: 70%;
				}
				@include breakpoint(medium-up) {
					margin: 0 0 15px;
					img {
						width: 100%;
					}
				}
			}
			figcaption {
				text-align: center;
				font-size: 13px;
				margin-bottom: 20px;
			}
			@include breakpoint(medium-up) {
				margin-bottom: 60px;
			}
		}
		
		
		figure.house {
			img {
				max-width: 600px;
			}
		}
		
		figure.graph {
			img {
				width: 70%;
				max-width: 332px;
			}
		}
		
		.textbox {
			@include breakpoint(medium-up) {
				padding: 0 60px;
				margin-bottom: 60px;
			}
		}
	}
	
	
	@include breakpoint(medium-up) {
		.section02 {
			.flexbox {
				flex-direction: row-reverse;
				align-items: center;
				figure {
					width: 40%;
					margin: 0;
				}
				.text {
					width: 60%;
				}
			}
		}
	
		.section03 {
			ul.flexbox {
				margin-top: 30px;
				li {
					width: 32%;
					figure {
						margin: 0 0 15px;
					}
					h4 {
						font-size: 15px;
						font-weight: bold;
						color: #333;
					}
					p {
						border: 1px solid #dcdcdc;
						padding: 10px;
						font-size: 13px;
					}
				}
			}
		}
	
	
		.section04 {
			.flexbox {
				margin-bottom: 40px;
				p {
					width: 40%;
				}
			}
			dl {
				border: 1px solid $medium-gray;
				display: flex;
				flex-direction: row;
				padding: 60px;
				flex-wrap: wrap;
				dt {
					font-size: 20px;
					border-bottom: none;
					width: 10em;
					span {
						&:after {
							content: "・・・";
						}
					}
					small {
						display: block;
						font-size: 13px;
					}
				}
				dd {
					width: calc(100% - 13em);
					margin-bottom: 35px;
				}
			}
		}
	}
}





// ご相談窓口
// ----------

body.page-template-inquiry {
	.contact_list {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 0;
		justify-content: space-between;

	}

	.contact_list > li {
		width: 48%;
		margin-bottom: 60px;
	}

	.contact_list li h4 {
		color: $primary-color;
		font-size: 18px;
		margin-top: 0;
	}

	.contact_list li h4 span {
		display: block;
		margin-top: 10px;
	}

	.contact_list li h4 span small {
		font-size: 12px;
		border-top: 1px solid; 
		padding-top: 10px;
		letter-spacing: 0.3em
	}

	.contact_list dl {
		border: 1px solid $primary-color;
		display: flex;
		flex-wrap: wrap;
		padding: 25px;
	}

	.contact_list dl dt {
		width: 12%;
		font-size: 12px;
		color: $primary-color;
		font-weight: bold;
		line-height: 24px;
	}

	.contact_list dl dd {
		width: 88%;
		margin: 0;
	}

	.contact_list > li:first-child {
		width: 100%;
		border: 2px solid $primary-color;
		outline: 1px solid $primary-color;
		outline-offset: -6px;
		margin-bottom: 60px;
		padding: 8px 0px 15px 0px;
	}

	.contact_list li:first-child h4 {
		text-align: center;
		font-size: 25px;
		margin: 20px;
	}

	.contact_list li:first-child ul {
		text-align: center;
		list-style: none;
		padding: 0;
	}

	.contact_list li:first-child ul span {
		font-size: 12px;
		font-weight: bold;
		color: $primary-color;
		padding-right: 16px;
	}

	.contact_list li:first-child ul a {
		font-weight: bold;
		font-size: 22px;
	}

	.contact_list > li:first-child ul li {
		padding-bottom: 16px;
	}

	.classname {
		font-size: 15px;
		padding-left: 7px;
	}
	
	@include breakpoint(small-only) {
		.contact_list {
			flex-direction: column;
			background: rgba($primary-color, .1);
			margin-left: -15px;
			margin-right: -15px;
			padding: 50px 15px;
			> li {
				width: 100%;
				margin-bottom: 30px;
				background: $white;
				padding: 20px;
				h4 {
					margin-bottom: 15px;
					span {
						display: none;
					}
				}
				dl {
					border: none;
					padding: 0;
				}
				&:first-child {
					padding: 30px 20px;
					margin-bottom: 30px;
					h4 {
						font-size: 18px;
						margin-top: 0;
						margin-bottom: 15px;
					}
					ul {
						text-align: left;
						padding: 0 20px;
						li {
							padding: 0;
						}
					}
				}
			}
		}
	}
	
}

// 私たちの要求
// ----------

body.page-template-demands {
	

	.demand_box {
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: column;
	}

	.demand_box li {
		text-align: center;
		border: 1px solid $primary-color;
		padding: 30px;
		margin: 40px 0px;
		position: relative;
		@include breakpoint(medium-up) {
			margin: 50px 0 60px;
		}
	}

	.number {
		position: absolute;
		top: -44px;
		left: 50%;
		width: 120px;
		margin-left: -60px;
		background-color: #fff;
	}

	.number span {
		font-size: 50px;
		color: $primary-color;
		border-bottom: 1px solid $primary-color;
		padding-bottom: 10px;
		font-weight: bold;
		letter-spacing: 0.1em;
		padding-left: 7px;
	}

	h3 {
		color: $primary-color;
		font-size: 26px;
		margin: 25px 0px 10px 0px;
		@include breakpoint(medium-up) {
			margin: 40px 0 30px;
		}
	}

	.demand_box li p {
		text-align: left;
		display: inline-block;
	}
	
}

// 関連リンク
// ----------

body.page-template-links {
	ul.relation_link {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
		flex-direction: column;
		li {
			span, a {
				display: block;
			}
			span {
				font-weight: bold;
			}
			a {
				word-break: break-all;
				font-size: 14px;
				margin-bottom: 20px;
			}
		}
		@include breakpoint(medium-up) {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			li {
				width: 46%;
				padding: 10px 0;
				span {
					font-size: 18px;
				}
			}
		}
	}



	
}

// 裁判の動き
// ----------

body.page-template-lawsuit {
	.section02 {
		.box {
			border: none;
			background: rgba($alert-color, .1);
			margin-bottom: 20px;
		}
		.lawsuit_list {
			list-style: none;
			padding: 0;
			> li {
				margin-bottom: 30px;
			}
			h4 {
				color: $secondary-color;
				margin-bottom: 5px;
				span {
					vertical-align: text-bottom;
					font-size: 14px;
					margin-left: 3px;
					&:before {
						content: "-";
						margin-right: 3px;
					}
				}
			}
			dl {
				dt {
					border-bottom: 1px dotted;
					padding-bottom: 3px;
					margin-bottom: 6px;
					&:not(:first-child) {
						margin-top: 20px;
					}
				}
				dd {
					margin: 0;
				}
			}
		}
	}
	.section03 {
		.other-area {
			margin-bottom: 20px;
			.head {
				display: flex;
				justify-content: space-between;
				position: relative;
				margin-bottom: 15px;
				align-items: center;
				&:before {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					background: $medium-gray;
					position: absolute;
					top: calc(50% - 1px);
					z-index: -1;
					
				}
				h4 {
					background: $primary-color;
					padding: 5px 10px;
					font-size: $global-font-size;
					color: $white;
					width: 120px;
					text-align: center;
					margin-bottom: 0;
					@include breakpoint(medium-up) {
						font-size: 18px;
						padding: 15px 20px;
						width: 200px;
					}
				}
				button {
					display: block;
					width: 40px;
					height: 40px;
					background: $white;
					border: 1px solid $medium-gray;
					border-radius: 50%;
					position: relative;
					&:before, &:after {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						display: block;
						background: $black;
						transform-origin: center;
					}
					&:before {
						width: 32%;
						height: 1px;
					}
					&:after {
						width: 1px;
						height: 32%;
					}
				}
			}
			.details {
				height: 0;
				opacity: 0;
				overflow: hidden;
				dl {
					dt {
						border-bottom: 1px dotted;
						padding-bottom: 3px;
						margin-bottom: 6px;
					}
					dd {
						margin: 0;
					}
				}
				@include breakpoint(medium-up) {
					width: 80%;
					margin: 0 auto;
					dl {
						display: flex;
						flex-wrap: wrap;
						margin-bottom: 0;
						dt {
							width: 9em;
							color: $secondary-color;
							border: none !important;
							&:after {
								content: "・・・";
							}
						}
						dd {
							width: calc(100% - 9em);
							ul {
								list-style: none;
								padding: 0;
							}
						}
					}
				}
			}
			&.open {
				.head {
					button {
						&:after {
							transform: translate(-50%, -50%) rotate(90deg);
						}
					}
				}
				.details {
					height: auto;
					opacity: 1;
					padding: 20px;
					border: 2px solid $light-gray;
					@include breakpoint(medium-up) {
						padding: 30px;
					}
				}
			}
		}
	}
	
	
	@include breakpoint(medium-up) {
		.section01 {
			figure {
				text-align: center;
				img {
					width: 70%;
				}
			}
		}
		
		.section02 {
			.area {
				position: relative;
				padding-top: 60px;
				margin-bottom: 60px;
			}
			.box {
				width: 70%;
				display: flex;
				padding: 50px 30px;
				figure {
					width: 50%;
					position: absolute;
					top: 10px;
					right: 0;
					margin: 0;
				}
			
				p {
					width: 70%;
				}
			}
		}
		
		.lawsuit_list {
			margin: 0;
			padding: 0 70px;
			> li {
				list-style: none;
				display: flex;
				margin-bottom: 60px;
				h4 {
					font-size: 32px;
					font-weight: bold;
					color: #0f4d8d;
					margin: 0;
					width: 30%;
					span {
						display: block;
						margin-top: 15px;
						&:before {
							display: none;
						}
					}
					small {
						border-top: 1px solid $secondary-color;
						font-size: 12px;
						letter-spacing: 5px;
						padding-top: 15px;
					}
				}
			}
			dl {
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;
				width: 70%;
			}
			dt {
				font-size: 16px;
				font-weight: bold;
				color: $secondary-color;
				width: 12em;
				border: none !important;
				&:after {
					content: "・・・・・・";
				}
			}
			dd {
				width: calc(100% - 12em);
				ul {
					list-style: none;
					padding: 0;
				}
			}
		}
	}
	@include breakpoint(large-up) {
		.section02 {
			.box {
				padding: 100px 60px;
			}
		}
	}
}




// 原告の活動
// ----------

body.page-template-activities {
	main {
		.box {
			border: none;
			margin-bottom: 30px;
			&.green {
				background: rgba($primary-color, .1);
			}
			&.yellow {
				background: rgba($alert-color, .1);
			}
			h4 {
				font-size: 18px;
				&:before {
					content: "「";
				}
				&:after {
					content: "」";
				}
			}
			p.name {
				border-bottom: 1px dotted $medium-gray;
				padding-bottom: 10px;
				margin-bottom: 15px;
				span {
					font-weight: bold;
					margin-left: 10px;
				}
			}
		}
	}
		
	
	@include breakpoint(medium-up) {
		main {
			.box {
				display: flex;
				position: relative;
				background: none !important;
				margin-top: 150px;
				padding: 0;
				.textbox {
					width: 70%;
					border: 1px solid $alert-color;
					position: relative;
					padding: 60px;
					&:after {
						position: absolute;
						content: "";
						background: rgba($alert-color, .1);
						height: 100%;
						width: 100%;
						top: 20px;
						left: 20px;
					}
				}
				h4 {
					font-size: 30px;
					span {
						display: inline-block;
					}
				}
				figure {
					position: absolute;
					z-index: 10;
					right: -48%;
					top: -12%;
					width: 50%;
				}
				&.green {
					flex-direction: column;
					align-items: flex-end;
					.textbox {
						border-color: $primary-color;
						&:after {
							background: rgba($primary-color, .1);
							left: -20px;
						}
					}
					figure {
						left: -48%;
						right: auto;
					}
				}
			}
		}
		
	}
}


// 動画コンテンツ
// ----------

body.page-template-movies {
	p.youtube_screen {
		font-size: 13px;
		border: 1px solid $medium-gray;
		padding: 10px;
		img {
			margin-right: 10px;
		}
		@include breakpoint(medium-up) {
			text-align: center;
			padding: 20px;
			margin-bottom: 40px;
		}
	}
	ul.movie-list {
		list-style: none;
		padding: 0;
		li {
			> div {
				display: flex;
				flex-direction: column;
				border-bottom: 1px solid #d2d2d2;
				padding: 15px;
			}
			figure {
				margin: 1rem 10px;
			}
			iframe {
				width: 100%;
				min-height: 200px;
			}
			h3 {
				font-size: $global-font-size;
				margin-bottom: 10px;
			}
			p {
				color: $black;
				font-size: 14px;
				margin-bottom: 3px;
			}
			span.readmore {
				display: block;
				text-align: right;
				font-size: 14px;
				margin-bottom: 10px;
			}
			.label {
				display: inline-block;
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 1px;
				padding: 2px 10px;
				border-radius: 60px;
				margin-bottom: 6px;
				min-width: 100px;
				text-align: center;
				&.lawsuit {
					background: $secondary-color;
				}
				&.activity {
					background: #e28807;
				}
				&.notice {
					background: $alert-color;
				}
			}
			time {
				display: block;
				text-align: right;
				color: $dark-gray;
				font-size: 14px;
			}
		}
		@include breakpoint(medium-up) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			&:after {
				content: "";
				display: block;
				width: 31%;
			}
			li {
				width: 31%;
				border: 1px solid #eee;
				padding: 1px;
				margin-bottom: 30px;
				> div {
					border: none;
					padding: 0;
					display: block;
				}
				figure {
					margin: 0;
				}
				section {
					padding: 15px;
				}
				.label {
					margin-bottom: 10px;	
				}
				time {
					text-align: left;
					font-size: 13px;
					margin-top: 10px;
				}
			}
		}
	}
	
	
	
}

