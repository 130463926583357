main {
	padding-top: 56px;
	padding-bottom: 50px;
	@include breakpoint(large-up) {
		padding-top: 70px;
		padding-bottom: 80px;
	}
	
}

.container {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;
	@include breakpoint(medium-up) {
		padding: 0 30px;
	}
	@include breakpoint(large-up) {
		max-width: $global-width;
		margin: 0 auto;
		padding: 0 80px;
	}
}

.box {
	border: 1px solid $medium-gray;
	padding: 18px;
	@include breakpoint(medium-up) {
		padding: 50px;
	}
}

.flexbox {
	display: flex;
	flex-direction: column;
	list-style: none;
	padding: 0;
	@include breakpoint(medium-up) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}

.section-title, .page-title {
	display: flex;
	align-items: center;
	text-align: center;
	font-size: 26px;
	background: rgba($primary-color, .1);
	color: $primary-color;
	letter-spacing: 2px;
	padding: 10px 0;
	margin-bottom: 25px;
	&::before,
	&::after {
		content: "";
		flex-grow: 1;
		height: 1px;
		background: $primary-color;
		display: block;
	}
	&::before {
		margin-right: .6em;
	}
	&::after {
		margin-left: .6em;
	}
	h2, h3 {
		font-size: 30px;
		margin-bottom: 0;
		letter-spacing: 2px;
	}
	small {
		display: block;
		font-size: 13px;
		font-weight: normal;
	}
	@include breakpoint(medium-up) {
		padding: 40px 0;
		font-size: 30px;
		margin-bottom: 60px;
	}
}

.section-headline {
	font-size: 22px;
	color: $primary-color;
	text-align: center;
	@include breakpoint(medium-up) {
		font-size: 26px;
		margin-bottom: 50px;
		span {
			position: relative;
			&:before, &:after {
				content: "";
				display: block;
				width: 19px;
				height: 19px;
				position: absolute;
				top: 50%;
				margin-top: -8px;
			}
			&:before {
				background: url(../images/headline-left.png) no-repeat;
				left: -35px;
			}
			&:after {
				background: url(../images/headline-right.png) no-repeat;
				right: -35px;
			}
		}
	}
}

.fadein {
  opacity : 0;
  transform : translate(0, 80px);
  transition : all 2s;
  &.right {
	  transform : translate(-80px, 0);
  }
  &.left {
	  transform : translate(80px, 0);
  }
	&.slidein {
	  opacity : 1;
	  transform : translate(0, 0);
	}
}

.button-area {
	text-align: center;
}


// パンくずリスト
// ----------

ul.breadcrumb {
	list-style: none;
	padding: 0;
	font-size: 13px;
	li {
		display: inline-block;
		&:not(:last-child) {
			&:after {
				content: "/";
				margin: 0 6px;
				color: $medium-gray;
			}
		}
		&:last-child {
			color: $medium-gray;
		}
	}
}


// 年表
// ----------

.timeline {
  position: relative;
  overflow: auto;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background: $primary-color;
    left: 5px;
  }
  h4 {
    background: $white;
    border: 1px solid $primary-color;
    color: $primary-color;
    padding: 0.5em;
    text-align: center;
    clear: both;
    position: relative;
    letter-spacing: 2px;
    transition-duration: .5s;
    max-width: 550px;
    margin: 0 auto 20px;
    &:hover {
	    background: $primary-color;
	    color: $white;
    }
    &:after {
	    position: absolute;
	    right: 10px;
	    font-family: FontAwesome;
	    content: "\f055";
	    font-weight: normal;
    }
    &.open {
	    background: $primary-color;
	    color: $white;
	    &:after {
		    content: "\f056";
	    }
	    &:hover {
		    background: lighten($primary-color, 10%);
	    }
    }
  }
  ul {
    list-style: none;
    padding: 0 0 0 23px;
    z-index: 1;
		height: 0;
		opacity: 0;
		overflow: hidden;
		margin-bottom: 0;
	  li {
	    background: lighten($primary-color, 50%);
	    padding: 1em;
	    margin-bottom: 1em;
	    position: relative;
	    &:before {
			  content:"";
			  z-index: 1;
			  position: absolute;
			  top: 56px;
			  left: -6px;
			  width: 12px;
			  height: 12px;
			  background-color: lighten($primary-color, 50%);
			  transform: rotate(45deg);
	    }
			&:after {
			  content:"";
			  z-index: 1;
			  position: absolute;
			  top: 56px;
			  left: -23px;
			  width: 11px;
			  height: 11px;
			  border: 2px solid $white;
			  border-radius: 100%;
			  background-color: $primary-color;
			  transition: .2s;
			}
			&:hover:after {
			  background-color: $alert-color;
			  top: 54px;
			  left: -25px;
			  width: 15px;
			  height: 15px;
			}
			time {
				display: inline-block;
				font-size: 14px;
				font-weight: bold;
			}
			p:last-child {
				margin-bottom: 0;
			}
	  }
	  &.open {
		  height: auto;
		  opacity: 1;
		  margin-bottom: 20px;
	  }
  }
	@include breakpoint(medium-up) {
    &:before {
      left: 50%;
    }
    h4 {
	    padding: 1em;
    }
    ul {
      padding-left: 0;
      margin: 0 auto;
	    li {
	      width: 47%;
	      padding: 20px;
		    &:nth-child(even) {
		      float: right;
		      margin-top: 2em;
		      &:after {
			      left: -32px
		      }
		      &:hover:after {
			      left: -34px;
		      }
		    }
		    &:nth-child(odd) {
		      float: left;
		      &:before {
					  right: -6px;
					  left: auto;
					  top: 16px;
		      }
		      &:after {
			      left: auto;
			      top: 18px;
			      right: -33px;
		      }
		      &:hover:after {
			      top: 16px;
			      right: -35px;
		      }
		    }
		    &:nth-of-type(2n+1) {
		      clear: both;
		    }
	    }
    }
	}
}


